.Card{
    width: 100%;
    height: 100%;
    background-color: #393e46;
    color: #eeeeee;
    /* padding: 1rem; */
    border: 4px solid #00adb5;
    /* box-shadow: 0 0 4px #00adb5; */
    border-radius: 15px;
}
.Card .Icon{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40%;
    /* background-color: #eeeeee; */
    /* background-color: #21324e; */
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
.Card .Icon img{
    width: 60%;
    height: 60%;
    object-fit: cover;
    object-position: center;
}
.Card .Details{
    display: flex;
    flex-direction: column;
    height: 60%;
    justify-content: center;
    padding: 1rem;
    text-align: center;

}
.Card .Details .City{
    font-size: 1.4rem;
}
.Card .Details .Temperature{
    font-size: 3rem;
}