.ForeCast{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}
.ForeCast .ForeCastWrapper{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    gap: 0.4rem;
    width: 100%;
    height: 60%;
    text-align: center;
}

@media(max-width: 800px){
    .ForeCast .ForeCastWrapper{
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        align-items: center;
        min-height: 400px;
        width: 100%;
    }
}