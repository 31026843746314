.Layout{
    display: grid;
    grid-template-rows: 20% 80%;
    width: 100%;
    height: 600px;
    background-color: #222831;
    /* background-color: coral; */
}
.Layout .Search{
    margin-bottom: 1rem;
    align-self: center;
}
.Layout .Search h1{
    color: #00adb5;
    text-align: center;
}

.Layout .Weather .WeatherWrapper{
    display: grid;
    grid-template-columns: 30% 70%;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}
.Layout .Weather .WeatherWrapper > *{
    padding: 0.5rem;
}

@media(max-width: 800px){
    .Layout .Weather .WeatherWrapper{
        display: flex;
        gap: 1rem;
        flex-direction: column;
        justify-content: center;
    }
}