.ForeCastSkeleton{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}
.ForeCastSkeleton .ForeCastSkeletonWrapper{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    gap: 0.4rem;
    width: 100%;
    height: 60%;
    text-align: center;
}

@media(max-width: 800px){
    .ForeCastSkeleton .ForeCastSkeletonWrapper{
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        align-items: center;
        width: 100%;
        min-height: 400px;
    }
}









.ForeCastSkeletonCard{
    position: relative;
    display: grid;
    grid-template-rows: 20% 40% 40%;
    align-items: center;
    height: 100%;
    background-color: #d3d3d3;
    padding: 1rem;
    border-radius: 15px;
    text-align: center;
    overflow: hidden;
}
.ForeCastSkeletonCard::before{
    content: '';
    position: absolute;
    top: 0;
    left: -25%;
    width: 20px;
    height: 100%;
    background: rgba(211, 211, 211, 1);
    box-shadow: 
    0px 0px 40px 20px rgba(211, 211, 211, 1), 
    -0px -0px 40px 20px rgba(211, 211, 211, 0.8);
    animation: loading 1500ms forwards infinite ease-in-out;
}
.ForeCastSkeletonCard > *{
    background-color: rgb(238, 236, 236);
    border-radius: 15px;
}
.ForeCastSkeletonCard .Date{
    height: 70%;
}
.ForeCastSkeletonCard .Icons{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

}
.ForeCastSkeletonCard .Temperatures{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40%;
    font-size: 2rem;
}

@keyframes loading{
    to{
        left: 120%;
    }
}



@media(max-width: 800px){
    .ForeCastSkeletonCard{
        min-width: 100%;
        max-width: 100%;
        justify-self: center;
    }
}

@media(max-width: 500px){
    .ForeCastSkeletonCard{
        min-width: 100%;
        max-width: 100%;
    }
}