.CurrentConditionSkeletonWrapper{
    max-width: 300px;
    height: 400px;
}
.CurrentConditionSkeleton{
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #d3d3d3;
    border-radius: 15px;
    overflow: hidden;
}
.CurrentConditionSkeleton::before{
    content: '';
    position: absolute;
    top: 0;
    left: -25%;
    width: 20px;
    height: 100%;
    background: rgba(211, 211, 211, 1);
    box-shadow: 
    0px 0px 40px 20px rgba(211, 211, 211, 1), 
    -0px -0px 40px 20px rgba(211, 211, 211, 0.8);
    animation: loading 1500ms forwards infinite ease-in-out;
}
.CurrentConditionSkeleton .Icon{
    height: 40%;
    background-color: rgb(238, 236, 236);
}
.CurrentConditionSkeleton .Details{
    display: flex;
    flex-direction: column;
    height: 60%;
    justify-content: center;
    padding: 1rem;
    text-align: center;

}
.CurrentConditionSkeleton .Details > *{
    background: rgb(238, 236, 236);
    margin: 0.5rem auto;
    border-radius: 15px;
}
.CurrentConditionSkeleton .Details .City{
    width: 220px;
    height: 40px;
}
.CurrentConditionSkeleton .Details .Today{
    width: 150px;
    height: 30px;
}
.CurrentConditionSkeleton .Details .Temperature{
    width: 100px;
    height: 50px;
}
.CurrentConditionSkeleton .Details .Condition{
    width: 110px;
    height: 30px;
}

@keyframes loading{
    to{
        left: 120%;
    }
}

@media(max-width: 500px){
    .CurrentConditionSkeletonWrapper{
        min-width: 300px;
    }
}

