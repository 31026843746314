.Intro{
    position: fixed;
    top: 0;
    left: 0;
    /* width: 100%; */
    /* height: 100%; */
    /* background: red; */
    z-index: 250;
    transition: opacity 200ms ease-in-out;
}
.opened{
    opacity: 1;
}
.closed{
    opacity: 0;
}