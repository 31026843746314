.Backdrop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 200;
    transition: 
        opacity 1000ms ease-in-out,
        transform 300ms ease-in;
}
.opened{
    opacity: 1;
    transform: translateY(0);
}
.closed{
    opacity: 0;
    transform: translateY(-100%);
}